import React, {useState, useEffect} from 'react'
import styled from "styled-components";
import axios from "axios";
import { IoClose } from "react-icons/io5";

function DettagliImporto({dati, chiudi, giorno}) {

  //recupero dati
  const [datiRecuperati, setDatiRecuperati] = useState(null);
  async function recuperoDati(){

    const data = {
      dati: dati,
      giorno: giorno
    }
    const response = await axios.post("https://www.app.nauticsm.it/dettagli-importo", data);
    setDatiRecuperati(response.data);
    console.log(response.data)
  }
  useEffect(() => {
    recuperoDati();
  }, [dati])

  //chiudi popup
  function chiudiPopup(){
    chiudi();
  }

  return (
    <Container>
      <InnerContainer>
        
        <div className="up-menu">
          <CloseIcon onClick={chiudiPopup}/>
        </div>
        
        <div className="table-container">
        {datiRecuperati && datiRecuperati.fatture.length == 0 && datiRecuperati.importi.length == 0 ? (
          <div className='errore'>
            <h3 style={{color: 'red'}}>Nessun importo presente!</h3>
          </div>
        ) : (
          <>
            {datiRecuperati && datiRecuperati.fatture.length >0 && (
              <>
                <h3>Fatture</h3>
                <table>
                  <thead>
                    <tr>
                      <th>N. Fattura</th>
                      <th>Data Fattura</th>
                      <th>Azienda</th>
                      <th>Importo</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {datiRecuperati.fatture.map((item) => (
                      <tr>
                        <td>{item.docNumber}</td>
                        <td>{new Date(item.docDate).toLocaleDateString('it-IT')}</td>
                        <td>{item.companyName}</td>
                        <td>{parseFloat(item.taxableAmount).toFixed(2)} €</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
            {datiRecuperati && datiRecuperati.importi.length >0 && (
              <>
                <br/>
                <h3>Importi Manuali</h3>
                <table>
                  <thead>
                    <tr>
                      
                      <th style={{paddingRight: '10px'}}>Categoria</th>
                      <th>Importo</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {datiRecuperati.importi.map((item) => (
                      <tr>
                        
                        <td style={{paddingRight: '10px'}}>{item.categoria}</td>
                        <td>{parseFloat(item.importo).toFixed(2)} €</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}

        </div>
        
      </InnerContainer>
    </Container>
  )
}

export default DettagliImporto;

const Container = styled.div`
  z-index: 9998;
  position: fixed;
  top:  0;
  left:  0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerContainer = styled.div`
  width: fit-content;
  max-height: 50vh; 
  border-radius: 10px;
  background-color: white;
  position: fixed;
  overflow-y: auto;

  thead{
    font-size: 20px;
  }

  th, td{
    text-align: left;
    padding-left: 0px;
    font-size: 20px;
  }

  h3{
    font-size: 24px;
  }

  td:nth-child(2), td:nth-child(1), th:nth-child(2), th:nth-child(1){
    padding-right: 20px;
  }

  .errore{
    margin: 30px 0px;
  }

  table{
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0; 
    font-size: 18px;
    text-align: left;
    border: 1px solid #ddd; 
    border-radius: 5px; 
    overflow: hidden; 
    
  }

  .table-container{
    margin: 10px 30px 0px 10px;
  }

  thead {
    background-color: rgba(33, 57, 64, .8);
    color: white;
    font-size: 20px;
  }

  th, td {
    padding: 10px 15px;
    border-bottom: 1px solid #ddd; 
  }

  tbody tr:hover {
    background-color: #f1f1f1; 
  }

  .up-menu{
    height: fit-content;
    position: sticky;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 1000;
    background-color: white;
    display: flex;
    justify-content: flex-end;
  }

`

const CloseIcon = styled(IoClose)`
  margin: 3px 0px;
  cursor: pointer;
  font-size: 36px;
  transition: all .3s linear;
  border-radius: 5px;


  &:hover{
    background-color: #213940;
    color: white;
  }
  
`